const BASE_COLORS = [
  "rgba(138,63,252)",
  "rgba(51,177,255)",
  "rgba(0,125,121)",
  "rgba(255,126,182)",
  "rgba(250,77,86)",
  "rgba(111,220,140)",
  "rgba(69,137,255)",
  "rgba(209,39,113)",
  "rgba(210,161,6)",
  "rgba(8,189,186)",
  "rgba(186,230,255)",
  "rgba(186,78,0)",
  "rgba(212,187,255)",
];

/** Arbitrary BASE_COLORS values to support Tailwind (no dynamic classes) */
export const BG_BASE_COLORS = [
  "bg-[rgba(186,78,0)]",
  "bg-[rgba(138,63,252,0.75)]",
  "bg-[rgba(51,177,255)]",
  "bg-[rgba(0,125,121)]",
  "bg-[rgba(255,126,182)]",
  "bg-[rgba(250,77,86)]",
  "bg-[rgba(111,220,140)]",
  "bg-[rgba(69,137,255)]",
  "bg-[rgba(209,39,113)]",
  "bg-[rgba(210,161,6)]",
  "bg-[rgba(8,189,186)]",
  "bg-[rgba(186,230,255)]",
  "bg-[rgba(212,187,255)]",
];

export const CHART_BACKGROUND_COLORS = BASE_COLORS.map((c) =>
  colorWithAlpha(c, 0.5)
);

export const CHART_BACKGROUND_HOVER_COLORS = BASE_COLORS.map((c) =>
  colorWithAlpha(c, 0.75)
);

export const CHART_BORDER_COLORS = CHART_BACKGROUND_HOVER_COLORS;
export const CHART_BORDER_HOVER_COLORS = BASE_COLORS;

export const TEXT_GRAY_400 = "#98A2B3";
export const TEXT_GRAY_500 = "#667085";

function colorWithAlpha(color: string, alpha: number) {
  return [color.slice(0, -1), `,${alpha}`, color.slice(-1)].join("");
}
